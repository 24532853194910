<template>
  <div class="h-100">
    <h4 class="profile-divider with-divider blue f-w-400">
      {{ $t("profile.account_preferences") }}
    </h4>
    <form action="" class="h-100 form-settings">
      <div class="platform">
        <p class="title">{{ $t("profile.platform_settings") }}</p>
        <div class="language-select">
          <BeMultiselect
            :initialValue="{ value: 'Fr', name: 'Français' }"
            :options="[{ value: 'Fr', name: 'Français' }]"
            label="name"
            trackBy="value"
          ></BeMultiselect>
        </div>
      </div>
      <div class="notification">
        <p class="title">{{ $t("profile.sms_notifications") }}</p>
        <div>
          <div class="notification-checkbox">
            <div class="pl-0 form-check">
              <div class="switch d-inline m-r-10">
                <input
                  id="notificable"
                  :checked="user.notifiable"
                  type="checkbox"
                  @change="updateUserPreference('notifiable')"
                />
                <label class="cr" for="notificable"></label>
              </div>
            </div>
            <small class="form-text text-muted">
              {{ $t("profile.sms_notifications_desc") }}
              <span
                v-if="
                  user.country !== null &&
                    (user.country.calling_code === '237' ||
                      user.country.calling_code === '225')
                "
              >
                ({{
                  user.country.calling_code === "237"
                    ? "Canal+, Eneo..."
                    : "SODECI, CIE..."
                }})
              </span>
              {{ $t("profile.sms_notification_on_transfer") }}
            </small>
          </div>
          <div class="notification-checkbox">
            <div class="pl-0 form-check">
              <div class="switch d-inline m-r-10">
                <input
                  id="depot_notifiable"
                  :checked="user.depot_notifiable"
                  type="checkbox"
                  @change="updateUserPreference('depot_notifiable')"
                />
                <label class="cr" for="depot_notifiable"></label>
              </div>
            </div>
            <small class="form-text text-muted">
              {{ $t("profile.sms_notifications_on_recharge") }}
            </small>
          </div>

          <div class="box"></div>
          <p class="text"></p>
        </div>
        <div class="notification-checkbox">
          <div class="box"></div>
          <p class="text"></p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BeMultiselect from "../../components/common/BeMultiselect";

export default {
  name: "FormSetting",
  computed: mapState("auth", ["user"]),
  components: { BeMultiselect },
  methods: {
    updateUserPreference(element) {
      switch (element) {
        case "notifiable":
          this.$store.dispatch("auth/updateUserPreference", {
            element: element,
            data: { notifiable: !this.user.notifiable },
          });
          break;
        case "depot_notifiable":
          this.$store.dispatch("auth/updateUserPreference", {
            element: element,
            data: { depot_notifiable: !this.user.depot_notifiable },
          });
          break;
      }
    },
  },
};
</script>

<style scoped></style>
